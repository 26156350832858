import React from 'react';
import LoginActivity from '../components/Host/Account/LoginActivity';
const LoginActivityPage = () => {
    return ( 
        <>
            <LoginActivity/>
        </>
     );
}
 
export default LoginActivityPage;