import React from 'react';
import BusinessTrip from '../components/Host/Account/businessTrip';
const BusinessPage = () => {
    return ( 
        <div>
            <BusinessTrip/>
        </div>
     );
}
 
export default BusinessPage;