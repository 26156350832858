import React from 'react';
import Register from '../../components/Auth/Register/register';
const RegisterPage = () => {
  return (
    <>
      <Register />
    </>
  );
}

export default RegisterPage;