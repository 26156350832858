import React from 'react';
import Taxes from '../components/Host/Account/taxes';

const TaxPage = () => {
    return ( 
        <div>
            <Taxes/>
        </div>
     );
}
 
export default TaxPage;