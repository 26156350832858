import React from 'react';
import PerosnalInfo from '../components/Host/Account/personalInfo';
const PersonalInfoPage = () => {
    return ( 
        <div>
            <PerosnalInfo/>
        </div>
     );
}
 
export default PersonalInfoPage;