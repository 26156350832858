import React from 'react';
import WorldwideInterest from '../components/Host/Account/worldwideInterest';
const WorldWide = () => {
    return ( 
        <div>
            <WorldwideInterest/>
        </div>
     );
}
 
export default WorldWide;