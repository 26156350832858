import React from 'react';
import Notifications from '../components/Host/Account/notification';

const NotificationPage = () => {
    return ( 
        <div>
            <Notifications/>
        </div>
     );
}
 
export default NotificationPage;