import React, {useState} from 'react';
import axios from 'axios';
import PhoneInput from "react-phone-input-2";
import FinishUpLoging from '../components/Auth/Register/finishingUpLoging';
import "react-phone-input-2/lib/style.css";
const SignupCompletePage = () => {
  return (
    <>
      <FinishUpLoging/>
    </>
  );
}

export default SignupCompletePage;