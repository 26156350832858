import React from 'react';
import ManageAccountHeader from '../components/Host/Account/manageAccountHeader';
import Coupon from '../components/Host/Account/coupon';
const CouponPage = () => {
    return ( 
        <>
            <ManageAccountHeader/>
            <Coupon/>
        </>
     );
}
 
export default CouponPage;