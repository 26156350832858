import React from 'react';
import ManageAccountHeader from '../components/Host/Account/manageAccountHeader';
import Privacy from '../components/Host/Account/privacy';
const PrivacyPage = () => {
    return ( 
        <>
            <ManageAccountHeader/>
            <Privacy/>
        </>
     );
}
 
export default PrivacyPage;