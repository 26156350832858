import React from 'react';
import Payment from '../components/Host/Account/payment';
import ManageAccountHeader from '../components/Host/Account/manageAccountHeader';
const PaymentPage = () => {
    return ( 
        <>
            <ManageAccountHeader/>
            <Payment/>
        </>
     );
}
 
export default PaymentPage;