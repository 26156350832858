import React from 'react';
import ManageAccountHeader from '../components/Host/Account/manageAccountHeader';
import Security from '../components/Host/Account/security';
const SecurityPage = () => {
    return ( 
        <>
            <ManageAccountHeader/>
            <Security/>
        </>
     );
}
 
export default SecurityPage;